export class CustomQuestion {
  constructor() {
    this.label = "";//question title
    this.description = "";
    this.required = false;
    this.hint = "";
    this.keyboard_type = 0;
  }

  toMap() {
    return {
      "label": this.label,
      "description": this.description,
      "required": this.required,
      "hint": this.hint,
      "keyboard_type": this.keyboard_type
    }
  }
}

export const BUTTON_ID_RECEPTION = "0"
export const BUTTON_ID_NO_RECEPTION = "1"
export const BUTTON_ID_CUSTOM_1 = "2"
export const BUTTON_ID_CUSTOM_2 = "3"
export const BUTTON_ID_CUSTOM_3 = "4"
export const BUTTON_ID_CUSTOM_4 = "5"