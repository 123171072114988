import React from "react";
import { DeleteOutlined, InboxOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Layout,
  Spin,
  Button,
  Progress,
  Card,
  Tabs,
  Popover,
  Upload,
  Input,
  message,
  Modal,
  Divider,
  Select,
  Row,
  Col,
  Radio,
  TreeSelect,
  Form,
} from "antd";
import { SketchPicker } from "react-color";
import DepartmentsHelpers from "helpers/DepartmentsHelpers";
import "assets/common.css";
import "assets/initial.css";
import config from "models/config";
import QRCode from "qrcode.react";
import Global from "models/Global";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingOperations from "reducks/settings/operations";
import * as loadingOperations from "reducks/loading/operations";
import { getCommonSelector, getCorporationIdSelector, getAdvanceSelector } from "reducks/settings/selectors";
import { getLoadingState } from "reducks/loading/selectors";
import { v4 as uuidv4 } from "uuid";
import { ImportCustomButtonSetting, parseFileToObject, KEY_VALIDATION_IMPORT_CUSTOM_QUESTION_SETTING } from "../components/devices/ImportCustomButtonSetting"
import { BUTTON_ID_RECEPTION, BUTTON_ID_NO_RECEPTION, BUTTON_ID_CUSTOM_1, BUTTON_ID_CUSTOM_2, BUTTON_ID_CUSTOM_3, BUTTON_ID_CUSTOM_4 } from 'models/class/CustomQuestionSetting';
const TabPane = Tabs.TabPane;
const Dragger = Upload.Dragger;
const Option = Select.Option;

const { TextArea } = Input;

const db = Global.firestore;
const storage = Global.storage;
const localize = Global.localize;
const letterColor = "letterColor";
const snooze = "snooze";
const camera = "camera";
const enableUserSearch = "enableUserSearch";
const receptionNumber = "receptionNumber";
const noReceptionNumber = "noReceptionNumber";
const customizeButton1 = "customizeButton1";
const customizeButton2 = "customizeButton2";
const customizeButton3 = "customizeButton3";
const customizeButton4 = "customizeButton4";
const httpsOnly = "httpsOnly";
const endpointSelectableOnly = "endpointSelectableOnly";

class SettingDevices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: null,
      currentColorPickerKey: null,
      datas: [],
      tabState: [],
      previewVisible: false,
      previewImage: "",
      visible: false,
      saving: false,
      departments: [],
      customQAFile: null,
      localQAFileVersion: 0
    }

    this.renderDevice = this.renderDevice.bind(this);
    this.renderDevices = this.renderDevices.bind(this);

    this.renderColorPickerButton = this.renderColorPickerButton.bind(this);
    this.onVisibleChange = this.onVisibleChange.bind(this);
    this.createData = this.createData.bind(this);
    this.onSave = this.onSave.bind(this);
    this.reloadData = this.reloadData.bind(this);

    this.onAddNewDevice = this.onAddNewDevice.bind(this);
    this.setCustomQAData = this.setCustomQAData.bind(this);
    this.uploadCustomQAData = this.uploadCustomQAData.bind(this);
    this.getPathForCustomQAFile = this.getPathForCustomQAFile.bind(this);
    this.handleChangeCustomizeButtonTitle = this.handleChangeCustomizeButtonTitle.bind(this);
  }

  createData = (index, dbData) => {
    return {
      index: index,
      key: "key_" + index.toString(),
      title: dbData ? dbData.title : localize.NewDevice,
      name: dbData ? dbData.name : localize.NewDevice,
      letterColor: dbData ? dbData.letter_color : "BLACK",
      snooze: dbData ? dbData.snooze : false,
      background: dbData ? dbData.background : null,
      camera: dbData ? dbData.camera : false,
      textHomeTitle: dbData ? dbData.texts.home_title : localize.DeviceTextHomeMessageSentence,
      textFinishMessage: dbData ? dbData.texts.finish_message : localize.DeviceTextFinishMessageSentence,
      confirmationMessage: dbData ? dbData.texts.confirmation_message : localize.DeviceTextFinishTitleSentence,
      enableUserSearch: dbData ? dbData.enable_user_search : false,
      httpsOnly: dbData ? dbData.https_only : false,
      endpointSelectableOnly: dbData ? dbData.endpoint_selectable_only : false,
      receptionNumber: dbData
        ? dbData.reception_number
        : {
          title: localize.ReceptionNumberButtonTitle,
          color: "#a2d7dd",
          finish_message: localize.DeviceTextFinishMessageSentence,
          error_message: localize.DeviceTextErrorMessageSentence
        },
      noReceptionNumber: dbData
        ? dbData.no_reception_number
        : {
          title: localize.NoReceptionNumberButtonTitle,
          color: "#a2d7dd",
          finish_message: localize.DeviceTextFinishMessageSentence
        },
      customizeButton1: dbData
        ? dbData.customize_button1
        : {
          title: null,
          visible: false,
          color: "rgb(0,1,0)",
          finish_message: localize.DeviceTextFinishMessageSentence
        },
      customizeButton2: dbData
        ? dbData.customize_button2
        : {
          title: null,
          visible: false,
          color: "rgb(0,1,0)",
          finish_message: localize.DeviceTextFinishMessageSentence
        },
      customizeButton3: dbData
        ? dbData.customize_button3
        : {
          title: null,
          visible: false,
          color: "rgb(0,1,0)",
          finish_message: localize.DeviceTextFinishMessageSentence
        },
      customizeButton4: dbData
        ? dbData.customize_button4
        : {
          title: null,
          visible: false,
          color: "rgb(0,1,0)",
          finish_message: localize.DeviceTextFinishMessageSentence
        },
    };
  };

  createTabState = () => {
    return {
      colorPickerVisible: {
        receptionNumber: false,
        noReceptionNumber: false,
        customizeButton1: false,
        customizeButton2: false,
        customizeButton3: false,
        customizeButton4: false,
      },
      uploadProgress: 0,
      formRef: React.createRef(),
    };
  };

  addNewData = (index) => {
    var object = this.createData(index);
    this.state.datas.push(object);

    if (this.state.datas.length === 1) {
      this.setState({
        activeKey: object.key,
      });
    }

    this.forceUpdate();
  };

  addData = (index, data, docId) => {
    var newObject = this.createData(index, data);
    console.log(newObject)
    newObject.docId = docId;
    newObject.createdAt = data.created_at;
    newObject.updatedAt = data.updated_at;
    newObject.deviceLoginToken = data.device_login_token;

    this.state.datas.push(newObject);

    if (this.state.datas.length === 1) {
      this.setState({
        activeKey: newObject.key,
      });
    }

    this.forceUpdate();
  };

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  async reloadData() {
    var db = Global.firestore;
    var self = this;
    this.setState({ loading: true });
    return new Promise(async (resolved, rejected) => {
      await this.props.actions.fetchSettings();
      const { corporationId } = this.props;
      this.setState({
        tabState: [],
        datas: [],
        departments: [],
        service: null,
      });
      var deviceRef = db.collection(corporationId).doc("db").collection("devices").orderBy("created_at", "asc");
      let deviceSnapshots = await deviceRef.get();
      this.setState({ loading: false });
      var index = 0;
      if (deviceSnapshots && deviceSnapshots.empty === false) {
        deviceSnapshots.forEach((snapshot) => {
          var tabState = this.createTabState();
          this.state.tabState.push(tabState);
          self.addData(index++, snapshot.data(), snapshot.id);
        });
      } else {
        var tabState = this.createTabState();
        this.state.tabState.push(tabState);
        self.addNewData(index++);
      }
      /** 連携先はTeamsかWowtalkかを確認 */
      var docRef = db.collection(corporationId).doc("db").collection("settings").doc("common");
      let settingSnapshots = await docRef.get();
      if (settingSnapshots.exists && settingSnapshots.data()["collaboration_service"]) {
        var service = settingSnapshots.data()["collaboration_service"];
        DepartmentsHelpers.loadData(corporationId).then((data) => {
          var treeData = DepartmentsHelpers.getTreeData(data);
          var treeSelectData = DepartmentsHelpers.getTreeSelectFormat(treeData, service == "teams");
          self.setState({ departments: treeSelectData, service });
        });
      }
      resolved()
    });
  }

  componentDidMount() {
    this.reloadData();
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      previewVisible: false,
    });
  };

  onVisibleChange = (index, key, visible) => {
    var tabState = this.state.tabState;
    tabState[index].colorPickerVisible[key] = visible;

    this.setState({ tabState });
  };

  renderColorPickerButton(index, rgb, visibleKey) {
    const outside = {
      style: {
        padding: "3px",
        width: "120px",
        background: "rgb(255, 255, 255)",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px",
        display: "inline-block",
        cursor: "pointer",
      },
    };

    const inside = {
      style: {
        paddingBottom: "4px",
        height: "24px",
        borderRadius: "2px",
        backgroundColor: rgb,
      },
    };

    var self = this;
    var tabState = this.state.tabState;

    var visible = tabState[index].colorPickerVisible[visibleKey];

    return (
      <Popover
        content={
          <SketchPicker
            color={self.state.datas[index][visibleKey].color}
            onChangeComplete={(color) => {
              self.state.datas[index][visibleKey].color = color.hex;
              self.forceUpdate();
            }}
            onChange={(color) => {
              self.state.datas[index][visibleKey].color = color.hex;
              self.forceUpdate();
            }}
          />
        }
        title={localize.ColorPicker}
        trigger="click"
        visible={visible}
        onVisibleChange={(visible) => {
          this.onVisibleChange(index, visibleKey, visible);
        }}
      >
        <Button {...outside} disabled={this.state.updating}>
          <div {...inside} />
        </Button>
      </Popover>
    );
  }

  onDeleteDevice(device) {
    const modal = Modal.confirm();
    const { corporationId } = this.props;
    modal.update({
      title: localize.DeviceDelete,
      content: localize.ConfirmDeviceDelete.replace("{DEVICE_NAME}", device.name),
      // transitionName: "am-slide-down",
      cancelText: localize.Cancel,
      onOk: async (e) => {
        /** Delete Device */
        await db.collection(corporationId).doc("db").collection("devices").doc(device.docId).delete();

        this.reloadData();
        message.info(localize.MesDeleteDeviceSuccess);
      },
      onCancel: (e) => {
        process.nextTick(() => {
          modal.destroy();
        });
      },
    });
  }

  onChangeRadioButton(index, value, item) {
    var datas = this.state.datas;
    switch (item) {
      case httpsOnly: {
        datas[index].httpsOnly = value ? true : false;
        break;
      }
      case letterColor: {
        datas[index].letterColor = value;
        break;
      }
      case snooze: {
        datas[index].snooze = value === 0 ? false : true;
        break;
      }
      case camera: {
        datas[index].camera = value === 0 ? false : true;
        break;
      }
      case enableUserSearch: {
        datas[index].enableUserSearch = value === 0 ? false : true;
        break;
      }
      case customizeButton1: {
        datas[index][customizeButton1].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([customizeButton1 + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case customizeButton2: {
        datas[index][customizeButton2].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([customizeButton2 + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case customizeButton3: {
        datas[index][customizeButton3].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([customizeButton3 + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case customizeButton4: {
        datas[index][customizeButton4].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([customizeButton4 + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case noReceptionNumber: {
        datas[index][noReceptionNumber].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([noReceptionNumber + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case receptionNumber: {
        datas[index][receptionNumber].visible = value === 0 ? false : true;
        this.state.tabState[index].formRef.current.validateFields([receptionNumber + "Groups" + index], { force: true }, (err, values) => { });
        break;
      }
      case endpointSelectableOnly: {
        datas[index].endpointSelectableOnly = value === 0 ? false : true;
        break;
      }
      default:
        break;
    }

    this.setState({ datas });
  }

  onChangeCustomizeButton1 = (rule, value, callback) => {
    this.handleChangeDepartmentCustomizeButton(customizeButton1, value, callback);
  };

  onChangeCustomizeButton2 = (rule, value, callback) => {
    this.handleChangeDepartmentCustomizeButton(customizeButton2, value, callback);
  };

  onChangeCustomizeButton3 = (rule, value, callback) => {
    this.handleChangeDepartmentCustomizeButton(customizeButton3, value, callback);
  };

  onChangeCustomizeButton4 = (rule, value, callback) => {
    this.handleChangeDepartmentCustomizeButton(customizeButton4, value, callback);
  };

  handleChangeDepartmentCustomizeButton = (itemName, value, callback) => {
    const keyArr = this.state.activeKey.split("_");
    const index = keyArr[keyArr.length - 1];

    if ((value === undefined || value === null || value === "") && this.state.datas[index][itemName].visible === true) {
      callback(localize.MesInputRequired);
    } else {
      callback();
    }
  };

  handleChangeCustomizeButtonTitle = (button, value) => {
    const keyArr = this.state.activeKey.split("_");
    const index = parseInt(keyArr[keyArr.length - 1], 10);
    const buttonTitle = button.field.replace(/Title[\d]*/, "");

    const previewVisible = this.state.datas[index][buttonTitle].visible;
    if (previewVisible && !value) {
      return Promise.reject(new Error(localize.PleaseInputTitleButton));
    }
    return Promise.resolve();
  };

  onAddNewDevice() {
    var index = this.state.datas.length;

    var tabState = this.createTabState();
    this.state.tabState.push(tabState);

    this.addNewData(index);
  }

  onSave(index) {
    const { validateFields, scrollToField } = this.state.tabState[index].formRef.current;
    const { corporationId } = this.props;
    validateFields([
      "deviceId" + index,
      "deviceTitle" + index,
      "textHomeTitle" + index,
      // "textFinishContent" + index,
      "confirmationMessage" + index,
      endpointSelectableOnly + index,
      receptionNumber + index,
      receptionNumber + "textFinishContent" + index,
      receptionNumber + "textErrorContent" + index,
      noReceptionNumber + "Title" + index,
      noReceptionNumber + "Groups" + index,
      noReceptionNumber + "textFinishContent" + index,
      customizeButton1 + "Title" + index,
      customizeButton1 + "Groups" + index,
      customizeButton1 + "textFinishContent" + index,
      customizeButton2 + "Title" + index,
      customizeButton2 + "Groups" + index,
      customizeButton2 + "textFinishContent" + index,
      customizeButton3 + "Title" + index,
      customizeButton3 + "Groups" + index,
      customizeButton3 + "textFinishContent" + index,
      customizeButton4 + "Title" + index,
      customizeButton4 + "Groups" + index,
      customizeButton4 + "textFinishContent" + index,
      KEY_VALIDATION_IMPORT_CUSTOM_QUESTION_SETTING
    ])
      .then(async (values) => {
        this.setState({ updating: true });
        const {
          background,
          confirmationMessage,
          camera,
          customizeButton1,
          customizeButton2,
          customizeButton3,
          customizeButton4,
          enableUserSearch,
          letterColor,
          name,
          noReceptionNumber,
          receptionNumber,
          snooze,
          // textFinishMessage,
          textHomeTitle,
          title,
          docId,
          createdAt,
          deviceLoginToken,
          httpsOnly,
          endpointSelectableOnly,
        } = this.state.datas[index];

        const object = {
          background: background,
          camera: camera,
          customize_button1: customizeButton1,
          customize_button2: customizeButton2,
          customize_button3: customizeButton3,
          customize_button4: customizeButton4,
          enable_user_search: enableUserSearch,
          endpoint_selectable_only: endpointSelectableOnly,
          letter_color: letterColor,
          name: name,
          no_reception_number: noReceptionNumber,
          reception_number: receptionNumber,
          snooze: snooze,
          texts: {
            confirmation_message: confirmationMessage ? confirmationMessage : null,
            // finish_message: textFinishMessage ? textFinishMessage : null,
            home_title: textHomeTitle ? textHomeTitle : null,
          },
          title: title,
          updated_at: new Date(),
          created_at: createdAt ? createdAt : new Date(),
          https_only: httpsOnly ? httpsOnly : false,
          device_login_token: deviceLoginToken ? deviceLoginToken : uuidv4(),
        };

        if (this.state.customQAFile != null) {
          let customQAData = await parseFileToObject(this.state.customQAFile);
          if (customQAData[BUTTON_ID_RECEPTION] != undefined) {
            object.reception_number.custom_questions = customQAData[BUTTON_ID_RECEPTION];
          } else {
            object.reception_number.custom_questions = [];
          }

          if (customQAData[BUTTON_ID_NO_RECEPTION] != undefined) {
            object.no_reception_number.custom_questions = customQAData[BUTTON_ID_NO_RECEPTION];
          } else {
            object.no_reception_number.custom_questions = [];
          }

          if (customQAData[BUTTON_ID_CUSTOM_1] != undefined) {
            object.customize_button1.custom_questions = customQAData[BUTTON_ID_CUSTOM_1];
          } else {
            object.customize_button1.custom_questions = [];
          }

          if (customQAData[BUTTON_ID_CUSTOM_2] != undefined) {
            object.customize_button2.custom_questions = customQAData[BUTTON_ID_CUSTOM_2];
          } else {
            object.customize_button2.custom_questions = [];
          }

          if (customQAData[BUTTON_ID_CUSTOM_3] != undefined) {
            object.customize_button3.custom_questions = customQAData[BUTTON_ID_CUSTOM_3];
          } else {
            object.customize_button3.custom_questions = [];
          }

          if (customQAData[BUTTON_ID_CUSTOM_4] != undefined) {
            object.customize_button4.custom_questions = customQAData[BUTTON_ID_CUSTOM_4];
          } else {
            object.customize_button4.custom_questions = [];
          }
          if (docId !== undefined) {
            await this.uploadCustomQAData(index)
          }
        }
        var task = null;

        if (docId) {
          task = db.collection(corporationId).doc("db").collection("devices").doc(docId).set(object, { merge: true });
        } else {
          task = db.collection(corporationId).doc("db").collection("devices").doc().set(object);
        }

        message.destroy();
        task
          .then(async (e) => {
            this.setState({
              updating: false,
            });
            if (docId) {
              message.info(localize.UpdateSuccess);
            } else {
              await this.reloadData();
              await this.uploadCustomQAData(index)
              message.info(localize.CreateNewTabSuccess);
            }
            if (this.state.customQAFile != null) {
              let nextVersion = this.state.localQAFileVersion + 1
              this.setState({
                localQAFileVersion: nextVersion,
                customQAFile: null
              })
            }
          })
          .catch((e) => {
            this.setState({ updating: false }, () => {
              message.warn(localize.UpdateFaile);
            });
          });
      })
      .catch(({ errorFields }) => {
        console.log(errorFields);
        const error = errorFields[0].name.join();
        const convertErrorFields = error.replace("Groups", "Title");
        scrollToField([convertErrorFields]);
        message.warn(localize.UpdateFaile);
        return;
      });
  }

  setCustomQAData(file) {
    console.log("SettingDevices setCustomQAData: file = %o", file);
    this.setState({ customQAFile: file });
  }

  async uploadCustomQAData(index) {
    const file = this.state.customQAFile;
    const storageRef = storage.ref();
    const path = this.getPathForCustomQAFile(index);
    const uploadTask = storageRef.child(path).put(file);
    return new Promise((resolved, rejected) => {
      uploadTask.then((snapshot) => {
        console.log(snapshot);
        resolved()
      }).catch((error) => {
        rejected(error);
      });
    }).then((data) => {
      return true;
    }).catch((err) => {
      console.log("uploadCustomQAData ERR : %o", err);
      return false;
    })
  }

  getPathForCustomQAFile(index) {
    const { common } = this.props;
    const tenantId = common.tenantId;
    const docId = this.state.datas[index].docId
    const fileName = docId + "_custom_question.csv";
    const path = `${tenantId}/custom-question/` + fileName;
    return path
  }

  renderDevice(index) {
    var self = this;
    const { corporationId } = this.props;

    const lineHeight = {
      style: {
        lineHeight: "48px",
      },
    };

    const linePossibleLength = {
      style: {
        marginLeft: "20px",
      },
    };

    const lineComment = {
      style: {
        marginLeft: "20px",
        color: "red",
      },
    };

    const lineMargin = {
      style: {
        marginBottom: "8px",
        marginTop: "8px",
      },
    };

    const propsTreeSelect = {
      style: { width: "100%" },
      size: "default",
      placeholder: localize.NotificationGroup,
      treeData: this.state.departments,
      disabled: this.state.updating,
      treeDefaultExpandAll: true,
      allowClear: true,
      multiple: false,
      dropdownStyle: { maxHeight: 400, overflow: "auto" },
    };

    // cameraオブジェクトが存在しない場合、trueとして扱う
    if ("camera" in self.state.datas[index] === false) {
      self.state.datas[index].camera = true;
    }

    // enableUserSearchオブジェクトが存在しない場合、falseとして扱う
    if ("enableUserSearch" in self.state.datas[index] === false) {
      self.state.datas[index].enableUserSearch = false;
    }

    if (self.state.datas[index].endpointSelectableOnly === undefined) {
      self.state.datas[index].endpointSelectableOnly = false;
    }

    if (!self.state.datas[index][customizeButton1].groups) {
      self.state.datas[index][customizeButton1].groups = null;
    }

    if (!self.state.datas[index][customizeButton2].groups) {
      self.state.datas[index][customizeButton2].groups = null;
    }

    if (!self.state.datas[index][customizeButton3].groups) {
      self.state.datas[index][customizeButton3].groups = null;
    }

    if (!self.state.datas[index][customizeButton4].groups) {
      self.state.datas[index][customizeButton4].groups = null;
    }

    if (!self.state.datas[index][receptionNumber].groups) {
      self.state.datas[index][receptionNumber].groups = null;
    }

    if (!self.state.datas[index][noReceptionNumber].groups) {
      self.state.datas[index][noReceptionNumber].groups = null;
    }

    if ("visible" in self.state.datas[index][noReceptionNumber] === false) {
      self.state.datas[index][noReceptionNumber].visible = true;
    }

    var progressBarVisible = false;
    if (self.state.tabState[index].uploadProgress > 0) {
      progressBarVisible = true;
    }

    const children = [];

    if (self.state.datas[index].groups) {
      self.state.datas[index].groups.map((e, index) => {
        return children.push(<Option key={index.toString()}>{e}</Option>);
      });
    }

    config.rootId = self.state.datas[index].docId;
    config.corporationId = corporationId;
    config.deviceLoginToken = self.state.datas[index].deviceLoginToken;

    return (
      <Form ref={this.state.tabState[index].formRef}>
        <Card
          title={localize.Overall}
          extra={
            <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => self.onDeleteDevice(self.state.datas[index])}>
              {localize.Delete}
            </Button>
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>QR</span>
            <QRCode value={JSON.stringify(config)} level={"H"} size={256} />
            <br />
          </div>

          {/* デバイス名(識別用) */}
          <span {...lineHeight}>{localize.DeviceNameForIdentification}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>

          <Form.Item
            name={"deviceId" + index}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 20, message: localize.ButtonTitleMaxLength },
              { type: 'string', whitespace: true, message: localize.MesInputRequired },
            ]}
            initialValue={self.state.datas[index].name}
          >
            <Input
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index].name = e.target.value;
              }}
            />
          </Form.Item>

          {/* 待受画面カードグループ */}
          <Card title={localize.StandbyScreen}>
            {/* タイトル (iPad下部に表示) */}
            <span {...lineHeight}>{localize.DeviceTitle}</span>
            <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 40)}</span>

            <Form.Item
              name={"deviceTitle" + index}
              initialValue={self.state.datas[index].title}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 40, message: localize.DeviceTitleMaxLength },
              ]}
            >
              <Input
                disabled={this.state.updating}
                onChange={(e) => {
                  self.state.datas[index].title = e.target.value;
                }}
              />
            </Form.Item>

            {/* 待受画面メッセージ  */}
            <span {...lineHeight}>{localize.DeviceTextHomeTitle}</span>
            <span {...lineComment}>{localize.CommentInputRequired}</span>
            <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 200)}</span>

            <Form.Item
              name={"textHomeTitle" + index}
              initialValue={self.state.datas[index].textHomeTitle}
              rules={[
                { required: true, message: localize.MesInputRequired },
                { max: 200, message: localize.HomeTextTitleMaxLength },
              ]}
            >
              <TextArea
                rows={3}
                disabled={this.state.updating}
                onChange={(e) => {
                  self.state.datas[index].textHomeTitle = e.target.value;
                }}
              />
            </Form.Item>

            {/* 文字色 */}
            <span {...lineHeight}>{localize.LetterColor}</span>
            <div>
              <Radio.Group
                value={self.state.datas[index].letterColor}
                onChange={(e) => {
                  this.onChangeRadioButton(index, e.target.value, letterColor);
                }}
              >
                <Radio value={"WHITE"}>{localize.White}</Radio>
                <Radio value={"BLACK"}>{localize.Black}</Radio>
              </Radio.Group>
            </div>
            <br />

            {/* 背景画像 */}
            <span {...lineHeight}>{localize.BackgroundImage}</span>
            <Dragger
              onPreview={this.handlePreview}
              onRemove={(file) => {
                var tabState = self.state.tabState;
                tabState[index].background = null;
                var datas = self.state.datas;
                datas[index].background = null;
                self.setState({ tabState });
              }}
              customRequest={(info) => {
                const { common } = this.props;
                const tenantId = common.tenantId;
                var storageRef = storage.ref();
                var fileName = info.file.uid + info.file.name;
                var path = `${tenantId}/devices/` + fileName;
                var uploadTask = storageRef.child(path).put(info.file);

                uploadTask.on(
                  "state_changed",
                  function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    self.state.tabState[index].uploadProgress = progress;
                    self.forceUpdate();
                  },
                  function (error) {
                    // Handle unsuccessful uploads
                    self.state.tabState[index].uploadProgress = 0;
                    self.forceUpdate();
                  },
                  function () {
                    self.state.tabState[index].uploadProgress = 0;
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                      var img = {
                        uid: info.file.uid,
                        name: fileName,
                        status: "done",
                        url: downloadURL,
                        ref: uploadTask.snapshot.ref.fullPath,
                      };
                      var tabState = self.state.tabState;
                      tabState[index].background = [img];
                      var datas = self.state.datas;
                      datas[index].background = [img];
                      self.setState({ tabState });
                    });
                    self.forceUpdate();
                  }
                );
              }}
              listType="picture"
              fileList={self.state.tabState[index].background ? self.state.tabState[index].background : self.state.datas[index].background}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p style={{ fontWeight: 700 }}>{localize.DraggerText}</p>
            </Dragger>

            {progressBarVisible ? <Progress percent={this.state.tabState[index].uploadProgress} /> : null}

            <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
              <img style={{ width: "100%" }} src={this.state.previewImage} alt={""} />
            </Modal>

            <Divider dashed {...lineMargin} />
          </Card>

          {/* ご確認メッセージ */}
          <span {...lineHeight}>{localize.ConfirmationMessage}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 200)}</span>
          <Form.Item
            name={"confirmationMessage" + index}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 200, message: localize.InputMax200Length },
            ]}
            initialValue={self.state.datas[index].confirmationMessage}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index].confirmationMessage = e.target.value;
              }}
            />
          </Form.Item>

          {/* スヌーズ(1分ごとに最大3回まで再通知) */}
          <span {...lineHeight}>{localize.HttpsOnlyTitle}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index].httpsOnly ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, httpsOnly);
              }}
            >
              <Radio value={1}>{localize.Yes}</Radio>
              <Radio value={0}>{localize.No}</Radio>
            </Radio.Group>
          </div>
          <br />

          {/* スヌーズ(1分ごとに最大3回まで再通知) */}
          <span {...lineHeight}>{localize.Snooze}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index].snooze ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, snooze);
              }}
            >
              <Radio value={1}>{localize.On}</Radio>
              <Radio value={0}>{localize.Off}</Radio>
            </Radio.Group>
          </div>
          <br />

          {/* カメラON/OFF */}
          <span {...lineHeight}>{localize.Camera}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index].camera ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, camera);
              }}
            >
              <Radio value={1}>{localize.On}</Radio>
              <Radio value={0}>{localize.Off}</Radio>
            </Radio.Group>
          </div>
          <br />

          {/* フリガナ検索機能 */}
          <span {...lineHeight}>{localize.EnableUserSearch}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index].enableUserSearch ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, enableUserSearch);
              }}
            >
              <Radio value={1}>{localize.On}</Radio>
              <Radio value={0}>{localize.Off}</Radio>
            </Radio.Group>
          </div>
          <br />

          {
            /* 末端しか選択させない */
            self.state.service === "wowtalk" ? (
              <div>
                <span {...lineHeight}>{localize.EndpointSelectableOnlyTitle}</span>
                <div>
                  <Radio.Group
                    disabled={self.state.updating}
                    value={self.state.datas[index].endpointSelectableOnly ? 1 : 0}
                    onChange={(e) => {
                      this.onChangeRadioButton(index, e.target.value, endpointSelectableOnly);
                    }}
                  >
                    <Radio value={1}>{localize.Yes}</Radio>
                    <Radio value={0}>{localize.No}</Radio>
                  </Radio.Group>
                </div>
              </div>
            ) : null
          }
        </Card>
        <br />

        {/* 受付番号あり */}
        <Card title={localize.ReceptionNumber} style={{ marginBottom: 16 }}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][receptionNumber].visible === undefined ? 1 : self.state.datas[index][receptionNumber].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, receptionNumber);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>

          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>
          <Form.Item
            name={receptionNumber + index}
            initialValue={self.state.datas[index][receptionNumber].title}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }]}
          >
            <Input
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][receptionNumber].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {this.renderColorPickerButton(index, self.state.datas[index][receptionNumber].color, receptionNumber)}
          </div>

          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputOption}</span>
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={receptionNumber + "Groups" + index}
            initialValue={self.state.datas[index][receptionNumber].groups}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][receptionNumber].groups = e;
              }}
            // style={{ width: "100%" }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>

          <Form.Item
            name={receptionNumber + "textFinishContent" + index}
            initialValue={self.state.datas[index][receptionNumber]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][receptionNumber]["finish_message"] = e.target.value;
              }}
              name={receptionNumber + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>

          {/* 受付失敗画面メッセージ(番号ありのみ) */}
          <span {...lineHeight}>{localize.DeviceTextErrorTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={receptionNumber + "textErrorContent" + index}
            initialValue={self.state.datas[index][receptionNumber]["error_message"] ?? localize.DeviceTextErrorMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][receptionNumber]["error_message"] = e.target.value;
              }}
              name={receptionNumber + "textErrorContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        {/* 受付番号なし */}
        <Card title={localize.NoReceptionNumber} style={{ marginBottom: 16 }}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][noReceptionNumber].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, noReceptionNumber);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>
          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>
          <Form.Item
            name={noReceptionNumber + "Title" + index}
            initialValue={self.state.datas[index][noReceptionNumber].title}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }]}
          >
            <Input
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][noReceptionNumber].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {this.renderColorPickerButton(index, self.state.datas[index][noReceptionNumber].color, noReceptionNumber)}
          </div>
          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputOption}</span>
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={noReceptionNumber + "Groups" + index}
            initialValue={self.state.datas[index][noReceptionNumber].groups}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][noReceptionNumber].groups = e;
              }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={noReceptionNumber + "textFinishContent" + index}
            initialValue={self.state.datas[index][noReceptionNumber]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][noReceptionNumber]["finish_message"] = e.target.value;
              }}
              name={noReceptionNumber + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        {/* カスタマイズボタン① */}
        <Card title={localize.CustomizeButton1}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][customizeButton1].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, customizeButton1);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>

          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>

          <Form.Item
            name={customizeButton1 + "Title" + index}
            initialValue={self.state.datas[index][customizeButton1].title}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }, { validator: this.handleChangeCustomizeButtonTitle }]}
          >
            <Input
              disabled={self.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton1].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {self.renderColorPickerButton(index, self.state.datas[index][customizeButton1].color, customizeButton1)}
          </div>

          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>

          <Form.Item
            style={{ marginBottom: "0px" }}
            name={customizeButton1 + "Groups" + index}
            rules={[{ validator: this.onChangeCustomizeButton1 }]}
            initialValue={self.state.datas[index][customizeButton1].groups}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][customizeButton1].groups = e;
              }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={customizeButton1 + "textFinishContent" + index}
            initialValue={self.state.datas[index][customizeButton1]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton1]["finish_message"] = e.target.value;
              }}
              name={customizeButton1 + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        {/* カスタマイズボタン② */}
        <Card title={localize.CustomizeButton2}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>

          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][customizeButton2].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, customizeButton2);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>

          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>

          <Form.Item
            name={customizeButton2 + "Title" + index}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }, { validator: this.handleChangeCustomizeButtonTitle }]}
            initialValue={self.state.datas[index][customizeButton2].title}
          >
            <Input
              disabled={self.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton2].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {self.renderColorPickerButton(index, self.state.datas[index][customizeButton2].color, customizeButton2)}
          </div>

          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>

          <Form.Item
            style={{ marginBottom: "0px" }}
            name={customizeButton2 + "Groups" + index}
            rules={[{ validator: this.onChangeCustomizeButton2 }]}
            initialValue={self.state.datas[index][customizeButton2].groups}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][customizeButton2].groups = e;
              }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={customizeButton2 + "textFinishContent" + index}
            initialValue={self.state.datas[index][customizeButton2]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton2]["finish_message"] = e.target.value;
              }}
              name={customizeButton2 + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        {/* カスタマイズボタン③ */}
        <Card title={localize.CustomizeButton3}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>
          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][customizeButton3].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, customizeButton3);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>

          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>

          <Form.Item
            name={customizeButton3 + "Title" + index}
            initialValue={self.state.datas[index][customizeButton3].title}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }, { validator: this.handleChangeCustomizeButtonTitle }]}
          >
            <Input
              disabled={self.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton3].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {self.renderColorPickerButton(index, self.state.datas[index][customizeButton3].color, customizeButton3)}
          </div>

          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={customizeButton3 + "Groups" + index}
            initialValue={self.state.datas[index][customizeButton3].groups}
            rules={[{ validator: this.onChangeCustomizeButton3 }]}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][customizeButton3].groups = e;
              }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={customizeButton3 + "textFinishContent" + index}
            initialValue={self.state.datas[index][customizeButton3]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton3]["finish_message"] = e.target.value;
              }}
              name={customizeButton3 + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        {/* カスタマイズボタン④ */}
        <Card title={localize.CustomizeButton4}>
          <span {...lineHeight}>{localize.DisplaySwitching}</span>

          <div>
            <Radio.Group
              disabled={self.state.updating}
              value={self.state.datas[index][customizeButton4].visible ? 1 : 0}
              onChange={(e) => {
                this.onChangeRadioButton(index, e.target.value, customizeButton4);
              }}
            >
              <Radio value={1}>{localize.Visible}</Radio>
              <Radio value={0}>{localize.Hidden}</Radio>
            </Radio.Group>
          </div>

          <span {...lineHeight}>{localize.ButtonTitle}</span>
          <span {...linePossibleLength}>{localize.LessThanCharacters.replace("{number}", 20)}</span>

          <Form.Item
            name={customizeButton4 + "Title" + index}
            initialValue={self.state.datas[index][customizeButton4].title}
            rules={[{ max: 20, message: localize.ButtonTitleMaxLength }, { validator: this.handleChangeCustomizeButtonTitle }]}
          >
            <Input
              disabled={self.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton4].title = e.target.value;
              }}
            />
          </Form.Item>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span {...lineHeight}>{localize.ButtonBackgroundColor}</span>
            {self.renderColorPickerButton(index, self.state.datas[index][customizeButton4].color, customizeButton4)}
          </div>

          <span {...lineHeight}>{localize.TransferGroup}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={customizeButton4 + "Groups" + index}
            initialValue={self.state.datas[index][customizeButton4].groups}
            rules={[{ validator: this.onChangeCustomizeButton4 }]}
          >
            <TreeSelect
              {...propsTreeSelect}
              onChange={(e) => {
                self.state.datas[index][customizeButton4].groups = e;
              }}
            />
          </Form.Item>
          {/* 受付完了画面メッセージ */}
          <span {...lineHeight}>{localize.DeviceTextFinishTitle}</span>
          <span {...lineComment}>{localize.CommentInputRequired}</span>
          <span {...linePossibleLength}>{localize.LessThanCharactersAndLines.replace("{number}", 60).replace("{lines}", 3)}</span>
          <Form.Item
            name={customizeButton4 + "textFinishContent" + index}
            initialValue={self.state.datas[index][customizeButton4]["finish_message"] ?? localize.DeviceTextFinishMessageSentence}
            rules={[
              { required: true, message: localize.MesInputRequired },
              { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
            ]}
          >
            <TextArea
              rows={3}
              disabled={this.state.updating}
              onChange={(e) => {
                self.state.datas[index][customizeButton4]["finish_message"] = e.target.value;
              }}
              name={customizeButton4 + "textFinishContent" + index}
              rules={[
                { required: false, message: localize.MesInputRequired },
                { max: 60, message: localize.DeviceTextFinishMessageMaxLength },
              ]}
            />
          </Form.Item>
        </Card>
        <br />

        <ImportCustomButtonSetting setCustomQAData={this.setCustomQAData} path={this.getPathForCustomQAFile(index)} localVersion={this.state.localQAFileVersion} />
        <br />
        {/* 保存 */}
        <Row>
          <Col offset={8} span={8}>
            <Button
              icon={<SaveOutlined />}
              type="danger"
              block
              disabled={this.state.updating}
              loading={this.state.updating}
              onClick={(e) => {
                this.onSave(index);
              }}
            >
              {localize.Save}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  renderDevices() {
    var self = this;
    return (
      <Layout>
        <Card title={localize.Device} style={{ margin: "16px" }}>
          <Spin size="large" spinning={this.state.loading}>
            <Tabs
              hideAdd={false}
              onChange={this.onChange}
              activeKey={this.state.activeKey}
              tabBarExtraContent={
                <Button icon={<PlusOutlined />} onClick={self.onAddNewDevice}>
                  {localize.NewDevice}
                </Button>
              }
              onEdit={this.onEdit}
              animated={{ linkBar: true, tabPane: true }}
            // tabPosition="top"
            >
              {this.state.datas.map((pane) => (
                <TabPane tab={pane.name} key={pane.key} forceRender={true}>
                  {this.renderDevice(pane.index)}
                </TabPane>
              ))}
            </Tabs>
          </Spin>
        </Card>
      </Layout>
    );
  }

  render() {
    // console.log(`this.state`, this.state);
    return this.renderDevices();
  }
}

// container component
const mapStateToProps = (state) => {
  return {
    loading: getLoadingState(state),
    settingAdvanceDomains: getAdvanceSelector(state),
    common: getCommonSelector(state),
    corporationId: getCorporationIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations, ...loadingOperations }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SettingDevices);
