import React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Input, Select, Row, Col, Button, Spin, message, Card, Form, Modal, Radio } from "antd";
import Global from "../models/Global";
import { LogoutOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Icon } from "@fluentui/react/lib/Icon";
import { initializeIcons } from "@uifabric/icons";

// Redux
import * as settingOperations from "reducks/settings/operations";
import * as loadingOperations from "reducks/loading/operations";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCommonSelector, getCorporationIdSelector } from "reducks/settings/selectors";
import { getLoadingState } from "reducks/loading/selectors";

const { Option } = Select;
const { TextArea } = Input;
const localize = Global.localize;
const db = Global.firestore;
initializeIcons();

class SettingCommon extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      loading: true,
      updating: false,
      mailInfoEditable: false,
      settingData: {},

      loginUrl: "",
      teamsLoginState: false,
      useBot: false,
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  onUpdate() {
    const { validateFields } = this.formRef.current;
    const { corporationId, common } = this.props;
    this.setState({ updating: true });

    validateFields()
      .then((values) => {
        const commonDoc = db.doc(`${corporationId}/db/settings/common`);
        const { wowtalkCompanyId, wowtalkOpenApiKey, wowtalkUserId, wowtalkPassword, notificationEmailSubject, notificationEmailBody } = values;
        let displayHisotrySetting = this.state.settingData.showHistory;
        let data = {
          // 通知メール設定
          notification_email: {
            subject: notificationEmailSubject,
            body: notificationEmailBody,
          },
          show_history: displayHisotrySetting,
        };

        // wowtalk連携設定
        if (common.collaborationService === "wowtalk") {
          data = {
            ...data,
            login_information: {
              wowtalk: {
                company_id: wowtalkCompanyId,
                open_api_key: wowtalkOpenApiKey,
                user_id: wowtalkUserId,
                password: wowtalkPassword,
              },
            },
          };
        }

        message.destroy();
        commonDoc
          .set(data, { merge: true })
          .then((ref) => {
            this.setState({ updating: false, settingData: values });
            message.success(localize.UpdateCompleted);
          })
          .catch((error) => {
            console.error(error);
            message.error(error.toString());
            this.setState({ updating: false });
          });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ updating: false });
      });
  }

  onTeamsLoginPopup = () => {
    const height = 700;
    const width = 700;
    const top = window.screenTop + window.innerHeight / 2 - height / 2;
    const left = window.screenLeft + window.innerWidth / 2 - width / 2;

    const params = `width=${width},height=${height},left=${left},top=${top},scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`;

    window.open(this.state.loginUrl, "MicroSoftLogin", params);
  };

  onTeamsLogout = () => {
    const func = Global.func;
    const teamsLogout = func.httpsCallable("setting-teamsLogout");
    const { corporationId } = this.props;
    Modal.confirm({
      title: localize.Logout,
      icon: <ExclamationCircleOutlined />,
      content: localize.LogoutConfirm,
      okText: localize.Run,
      okType: "danger",
      cancelText: localize.Cancel,
      onOk: async () => {
        try {
          const result = await teamsLogout({ corporationId: corporationId });
          if (result.data.code === 200) {
            message.success(localize.LogoutSuccess);
          } else {
            console.error(result);
            message.success(localize.LogoutFailed);
          }
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() { },
    });
  };

  renderForm() {
    const { common } = this.props;
    const { collaborationService, teamsBotTenantId } = common;
    console.log("TenantID:" + teamsBotTenantId);

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 20 },
      },
    };

    // const tailFormItemLayout = {
    //   wrapperCol: {
    //     xs: {
    //       span: 24,
    //       offset: 0,
    //     },
    //     sm: {
    //       span: 16,
    //       offset: 8,
    //     },
    //   },
    // };

    return (
      <div>
        <Form ref={this.formRef}>
          {/* WowTalk連携設定 */}
          {collaborationService === "wowtalk" && (
            <Card title={localize.SettingWTLogin} style={{ margin: "16px" }}>
              <div style={{ marginBottom: "15px" }}>
                <span>&nbsp;&nbsp;{localize.SettingWTLoginDescription}</span>
              </div>

              {/* OpenApi key */}
              <Form.Item
                {...formItemLayout}
                label="OpenApi Key"
                name="wowtalkOpenApiKey"
                rules={[
                  { required: true, message: localize.MesOpenAPIKey },
                  { pattern: new RegExp(/^[0-9a-z]*$/), message: localize.HalfSizeLowercaseAndNumbers },
                ]}
              >
                <Input disabled={this.state.updating} />
              </Form.Item>

              {/* 法人ID */}
              <Form.Item
                {...formItemLayout}
                label="法人ID"
                name="wowtalkCompanyId"
                rules={[
                  { required: true, message: localize.MesPleaseEnterCompanyID },
                  { pattern: new RegExp(/^[0-9a-z]*$/), message: localize.HalfSizeLowercaseAndNumbers },
                ]}
              >
                <Input disabled={this.state.updating} />
              </Form.Item>

              {/* ユーザーID */}
              <Form.Item
                {...formItemLayout}
                label={localize.UserID}
                name="wowtalkUserId"
                rules={[
                  { required: true, message: localize.MesPleaseEnterUserID },
                  { pattern: new RegExp(/^[0-9a-z|@\\.-]*$/), message: localize.PleaseInputUserId },
                ]}
              >
                <Input disabled={this.state.updating} />
              </Form.Item>

              {/* パスワード */}
              <Form.Item
                {...formItemLayout}
                label={localize.Password}
                name="wowtalkPassword"
                rules={[{ required: true, message: localize.MesEnterYourPassword }]}
              >
                <Input type="password" disabled={this.state.updating} autocomplete={true} />
              </Form.Item>

              {/* OoenApi Urrl */}
              <Form.Item
                {...formItemLayout}
                label="OpenApi URL"
                name="wowtalkOpenApiUrl"
                rules={[{ required: true, message: localize.MesPleaseEnterURL }]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Card>
          )}

          {/* Microsoft Teams連携設定 */}
          {collaborationService === "teams" && !teamsBotTenantId && (
            <Card title={localize.SettingTeamsLogin} style={{ margin: "16px" }}>
              {this.state.teamsLoginState ? (
                <>
                  <Form.Item {...formItemLayout} label="ログイン名" name="teamsLoginUserDisplayName">
                    <Input disabled={!this.state.mailInfoEditable ? true : this.state.updating} />
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="ログインメールアドレス" name="teamsLoginUserMail">
                    <Input disabled={!this.state.mailInfoEditable ? true : this.state.updating} />
                  </Form.Item>
                  <Row>
                    <Col offset={4} span={2}>
                      <Form.Item>
                        <Button icon={<LogoutOutlined />} type="danger" onClick={this.onTeamsLogout} loading={this.state.loading}>
                          {localize.Logout}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: "15px" }}>
                    <span>&nbsp;&nbsp;{localize.SettingTeamsLoginDescription}</span>
                  </div>
                  <Form.Item {...formItemLayout} label={localize.TeamsLogin} name="teamsLogout">
                    <Row>
                      <Col>
                        <Button
                          onClick={this.onTeamsLoginPopup}
                          loading={this.state.loading}
                          // icon={<LoginOutlined />}
                          icon={<Icon iconName="TeamsLogo16" style={{ paddingRight: "8px" }} />}
                          style={{ background: "#4B53BC", color: "#ffffff", borderColor: "#ffffff" }}
                        >
                          {localize.SignIn}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Card>
          )}

          {/* 通知メール設定 */}
          <Card title={localize.SettingMail} style={{ margin: "16px" }}>
            {/* アカウント */}
            <Form.Item
              {...formItemLayout}
              label={localize.Account}
              name="notificationEmailAccount"
              rules={[{ required: true, message: localize.MesPleaseEnterAccount }]}
            >
              <Input disabled={!this.state.mailInfoEditable ? true : this.state.updating} />
            </Form.Item>

            {/* パスワード */}
            <Form.Item
              {...formItemLayout}
              label={localize.Password}
              name="notificationEmailPassword"
              rules={[{ required: true, message: localize.MesPleaseEnterMailPassword }]}
            >
              <Input type="password" disabled={!this.state.mailInfoEditable ? true : this.state.updating} />
            </Form.Item>

            {/* FROM */}
            <Form.Item
              {...formItemLayout}
              label="FROM"
              name="notificationEmailFrom"
              rules={[{ required: true, message: localize.MesPleaseEnterFromMail }]}
            >
              <Input disabled={!this.state.mailInfoEditable ? true : this.state.updating} />
            </Form.Item>

            {/* 送信方式 */}
            <Form.Item {...formItemLayout} label={localize.MailType} name="notificationEmailType" rules={[{ required: false }]}>
              <Select style={{ width: 120 }}>
                <Option value="GMail">GMail</Option>
              </Select>
            </Form.Item>

            {/* メール(件名) */}
            <Form.Item
              {...formItemLayout}
              label={localize.Subject}
              name="notificationEmailSubject"
              rules={[{ required: true, message: localize.MesPleaseEnterEmailSubject }]}
            >
              <Input disabled={this.state.updating} />
            </Form.Item>

            {/* メール(内容) */}
            <Form.Item
              {...formItemLayout}
              label={localize.MailContent}
              name="notificationEmailBody"
              rules={[{ required: true, message: localize.MesPleaseEnterMailContent }]}
            >
              <TextArea rows={10} disabled={this.state.updating} />
            </Form.Item>
          </Card>
          {/*履歴表示設定 */}
          <Card title={localize.HistoryDisplaySettingTitle} style={{ margin: "16px" }}>
            <div>{localize.HistorySettingDescription}</div>
            <Radio.Group
              defaultValue={this.state.settingData.showHistory}
              onChange={(e) => {
                let obj = this.state.settingData;
                obj.showHistory = e.target.value;
                this.setState({ settingData: obj });
              }}
            >
              <Radio value={1}>{localize.DisplayHistoryLabel}</Radio>
              <Radio value={0}>{localize.NotDisplayHistoryLabel}</Radio>
            </Radio.Group>
          </Card>
          {/* 更新 */}
          <Row>
            <Col offset={8} span={8}>
              <Form.Item>
                <Button
                  icon={<SaveOutlined />}
                  type="danger"
                  block
                  disabled={this.state.updating}
                  loading={this.state.updating}
                  htmlType="submit"
                  onClick={this.onUpdate}
                >
                  {localize.Update}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  renderLoading() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Spin size="large" />
      </div>
    );
  }

  reloadData = () => {
    this.props.actions.fetchSettings().then(async () => {
      const { corporationId } = this.props;
      const commonDoc = db.doc(`${corporationId}/db/settings/common`);
      const func = Global.func;

      const self = this;
      this.setState({ loading: true }, (e) => {
        commonDoc.onSnapshot(async (snapshot) => {
          if (snapshot && snapshot.exists) {
            const data = snapshot.data();
            console.log(`data`, data);
            const { login_information, notification_email: notificationEmail, collaboration_service, show_history } = data;
            const { teams, wowtalk } = login_information;

            let obj = {};
            let valueBody = {
              // 通知メール設定
              notificationEmailAccount: notificationEmail.account,
              notificationEmailBody: notificationEmail.body,
              notificationEmailFrom: notificationEmail.from,
              notificationEmailPassword: notificationEmail.password,
              notificationEmailSubject: notificationEmail.subject,
              notificationEmailType: "GMail",
              //履歴表示設定
              showHistory: show_history !== undefined ? show_history : 1
            };

            if (collaboration_service === "teams") {
              valueBody = {
                ...valueBody,
                teamsLoginUserDisplayName: teams.login_user_display_name,
                teamsLoginUserMail: teams.login_user_mail,
              };
            } else if (collaboration_service === "wowtalk") {
              valueBody = {
                ...valueBody,
                wowtalkOpenApiKey: wowtalk.open_api_key,
                wowtalkCompanyId: wowtalk.company_id,
                wowtalkUserId: wowtalk.user_id,
                wowtalkPassword: wowtalk.password,
                wowtalkOpenApiUrl: wowtalk.open_api_url,
              };
            }

            // teamsログイン確認
            if ((teams.access_token && teams.refresh_token) || (teams.use_bot && teams.access_token)) {
              obj.teamsLoginState = true;
            } else {
              obj.teamsLoginState = false;
            }

            // microsoftロールバックURL
            if (!this.state.loginUrl && collaboration_service === "teams") {
              const teamsLoginUrl = func.httpsCallable("setting-teamsLoginUrl");
              const result = await teamsLoginUrl({ corporationId: corporationId });
              obj.loginUrl = result.data.url;
            }

            obj.loading = false;
            obj.settingData = valueBody;
            self.setState(obj);

            try {
              self.formRef.current.setFieldsValue(valueBody);
            } catch (error) {
              //エラー無視
            }
          }

          this.setState({ loading: false });
        });
      });
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loading !== nextState.loading) {
      return true;
    } else if (this.state.settingData !== nextState.settingData) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.reloadData();
  }

  render() {
    return <div>{this.state.loading ? this.renderLoading() : this.renderForm()}</div>;
  }
}

// container component
const mapStateToProps = (state) => {
  return {
    loading: getLoadingState(state),
    common: getCommonSelector(state),
    corporationId: getCorporationIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations, ...loadingOperations }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SettingCommon);
