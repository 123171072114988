import React, { useEffect, useState } from 'react'
import { Upload, Button, Card, Typography, Form, Input } from 'antd';
import { UploadOutlined, WarningOutlined } from '@ant-design/icons';
import Global from "../../models/Global";
import Papa from "papaparse";
import { CustomQuestion, BUTTON_ID_RECEPTION, BUTTON_ID_NO_RECEPTION, BUTTON_ID_CUSTOM_1, BUTTON_ID_CUSTOM_2, BUTTON_ID_CUSTOM_3, BUTTON_ID_CUSTOM_4 } from 'models/class/CustomQuestionSetting';
import CustomButtonSettingPreviewTable from './CustomButtonSettingPreviewTable';
const storage = Global.storage;
const localize = Global.localize;
const { Link } = Typography

const KEY_TARGET_RECEPTION_NUMBER = "受付番号ありボタン"
const KEY_TARGET_NO_RECEPTION_NUMBER = "受付番号なしボタン"
const KEY_TARGET_CUSTOM_BUTTON_1 = "カスタマイズボタン1"
const KEY_TARGET_CUSTOM_BUTTON_2 = "カスタマイズボタン2"
const KEY_TARGET_CUSTOM_BUTTON_3 = "カスタマイズボタン3"
const KEY_TARGET_CUSTOM_BUTTON_4 = "カスタマイズボタン4"
const downloadLabel = {
  style: {
    lineHeight: "48px",
    fontWeight: "bold"
  },
};
const uploadProps = {
  name: 'file',
  accept: ".csv",
};

const MAX_LABEL_LENGTH = 30;
const MAX_DESC_LENGTH = 90;
const MAX_HINT_LENGTH = 20;

function tryParseItem(item) {
  let keys = Object.keys(item)
  let id = item[keys[0]]//id
  if (id === "例" || item[keys[1]] === "") {//label
    return null
  }
  let question = new CustomQuestion()
  question.label = item[keys[1]]
  question.description = item[keys[2]]
  question.required = item[keys[3]] === "1"
  question.hint = item[keys[4]]
  question.keyboard_type = item[keys[5]]
  return question
}
export const KEY_VALIDATION_IMPORT_CUSTOM_QUESTION_SETTING = "custom_question_setting";
export async function parseFileToObject(file) {
  return new Promise((resolved, rejected) => {
    Papa.parse(file, {
      encoding: "Shift-JIS",
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let newData = {};

        for (var i = 0; i < results.data.length; i++) {
          let item = results.data[i];
          let question = tryParseItem(item)
          if (question == null) { continue }
          let dic = question.toMap()
          // console.log(dic)
          let keys = Object.keys(item)
          if (item[KEY_TARGET_RECEPTION_NUMBER] === "1") {
            if (newData[BUTTON_ID_RECEPTION] === undefined) { newData[BUTTON_ID_RECEPTION] = [] }
            newData[BUTTON_ID_RECEPTION].push(dic);
          }
          if (item[KEY_TARGET_NO_RECEPTION_NUMBER] === "1") {
            if (newData[BUTTON_ID_NO_RECEPTION] === undefined) { newData[BUTTON_ID_NO_RECEPTION] = [] }
            newData[BUTTON_ID_NO_RECEPTION].push(dic);
          }
          if (item[KEY_TARGET_CUSTOM_BUTTON_1] === "1") {
            if (newData[BUTTON_ID_CUSTOM_1] === undefined) { newData[BUTTON_ID_CUSTOM_1] = [] }
            newData[BUTTON_ID_CUSTOM_1].push(dic);
          }
          if (item[KEY_TARGET_CUSTOM_BUTTON_2] === "1") {
            if (newData[BUTTON_ID_CUSTOM_2] === undefined) { newData[BUTTON_ID_CUSTOM_2] = [] }
            newData[BUTTON_ID_CUSTOM_2].push(dic);
          }
          if (item[KEY_TARGET_CUSTOM_BUTTON_3] === "1") {
            if (newData[BUTTON_ID_CUSTOM_3] === undefined) { newData[BUTTON_ID_CUSTOM_3] = [] }
            newData[BUTTON_ID_CUSTOM_3].push(dic);
          }
          if (item[KEY_TARGET_CUSTOM_BUTTON_4] === "1") {
            if (newData[BUTTON_ID_CUSTOM_4] === undefined) { newData[BUTTON_ID_CUSTOM_4] = [] }
            newData[BUTTON_ID_CUSTOM_4].push(dic);
          }
        }

        resolved(newData);
      }
    })
  })
}

export function ImportCustomButtonSetting(props) {
  const [data, setData] = useState({})
  const [initData, setInitData] = useState({})
  const [hasError, setHasError] = useState(false)
  const [fileList, setFileList] = useState([])
  useEffect(() => {
    fetchCurrentSetting().then(async (blob) => {
      const file = new File([blob], "current_setting.csv", { type: "text/csv" })
      let data = await setNewData(file)
      setInitData(data)
      setFileList([])
      setHasError(false)
    })
  }, [props.localVersion])

  const setNewData = async (file) => {
    return new Promise((resolved, rejected) => {
      Papa.parse(file, {
        encoding: "Shift-JIS",
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          let newData = []
          let newHasError = false
          for (var i = 0; i < results.data.length; i++) {
            let item = results.data[i];
            let question = tryParseItem(item)
            if (question == null) { continue }
            let dic = question.toMap()
            //validation check
            if (dic["label"] == undefined
              || dic["label"].length > MAX_LABEL_LENGTH
            ) {
              dic["label_error"] = 1
              newHasError = true
            }
            if (dic["description"].length > MAX_DESC_LENGTH) {
              dic["desc_error"] = 1
              newHasError = true
            }
            if (dic["hint"].length > MAX_HINT_LENGTH) {
              dic["hint_error"] = 1
              newHasError = true
            }

            dic["required"] = (dic["required"] == 1) ? "必須" : "-"
            dic["keyboard_type"] = (dic["keyboard_type"] == 1) ? "数字" : "通常"

            dic["key"] = i
            dic["reception"] = (item[KEY_TARGET_RECEPTION_NUMBER] === "1") ? "●" : "-"
            dic["no_reception"] = (item[KEY_TARGET_NO_RECEPTION_NUMBER] === "1") ? "●" : "-"
            dic["custom_button_1"] = (item[KEY_TARGET_CUSTOM_BUTTON_1] === "1") ? "●" : "-"
            dic["custom_button_2"] = (item[KEY_TARGET_CUSTOM_BUTTON_2] === "1") ? "●" : "-"
            dic["custom_button_3"] = (item[KEY_TARGET_CUSTOM_BUTTON_3] === "1") ? "●" : "-"
            dic["custom_button_4"] = (item[KEY_TARGET_CUSTOM_BUTTON_4] === "1") ? "●" : "-"
            newData.push(dic)
          }
          setData(newData)
          setHasError(newHasError)
          resolved(newData)
        }
      })
    })

  }
  const fetchCurrentSetting = async () => {
    const { path } = props;
    const storageRef = storage.ref();
    const downloadUrlTask = storageRef.child(path).getDownloadURL()
    return new Promise((resolved, rejected) => {
      downloadUrlTask.then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          resolved(blob)
        };
        xhr.onerror = (event) => {
          console.log(event)
          rejected("cors error or file not found")
        }
        xhr.open('GET', url);
        xhr.send();
        // This can be downloaded directly:
      }).catch((error) => {
        rejected(error);
      });
    }).catch((err) => {
      console.log(err)
    })
  }

  const onDownloadCurrentSetting = async () => {
    let blob = await fetchCurrentSetting()
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = 'custom_question.csv';
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  }

  return (
    <Card
      title="質問項目カスタマイズ"
    >
      <Form.Item
        name={KEY_VALIDATION_IMPORT_CUSTOM_QUESTION_SETTING}
        rules={[
          {
            validator: (_, value) =>
              hasError ? Promise.reject(new Error('custom question has error')) : Promise.resolve(),
          },
        ]}
        style={{ display: 'none' }}
      >
        <Input key={"key_" + hasError} defaultValue={hasError ? "" : "1"} />
      </Form.Item>

      <div>
        <div className="attention">
          <div className="icon-warning">
            <WarningOutlined />
          </div>
          <div className="attention-content">
            <div>{localize.Attention1}</div>
            <div>{localize.Attention2}</div>
            <div>{localize.Attention5}</div>
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <Upload {...uploadProps}
          maxCount={1}
          fileList={fileList}
          beforeUpload={async (file) => {
            //!TODO! validation check
            await setNewData(file)
            setFileList([file])
            props.setCustomQAData(file);
            return false;
          }}
          onRemove={() => {
            props.setCustomQAData(null)
            setData(initData)
            setFileList([])
            return true
          }}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <div className="link">
          <span style={{ marginLeft: "8px", marginRight: "16px" }}></span>
          <Link href="https://doc.wowdesk.jp/wowdesk_custom_question_template.csv" target="_blank">
            {localize.LinkTitle}
          </Link>
          <div style={{ clear: "both" }} />
        </div>

        {Object.keys(data).length > 0 &&
          <>
            <span {...downloadLabel}>現在の設定</span>
            <Link style={{ marginLeft: "16px" }} onClick={onDownloadCurrentSetting}>現在の設定をダウンロード</Link>
            <CustomButtonSettingPreviewTable dataSource={data} hasError={hasError} />
          </>
        }
      </div>
    </Card>
  )
}
