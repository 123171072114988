import Global from "../models/Global";
import config from "../models/config";

const DepartmentsHelpers = {
  loadData: async (corporationId) => {
    var dataList = [];
    await DepartmentsHelpers.getDepartmentInfo(corporationId).then((data) => {
      dataList = data.syncedData;
    });
    return dataList;
  },

  loadDepartmentData: async (reqData, corporationId, collaborationService) => {
    if (reqData.syncedData && reqData.latestSyncDate) {
      return reqData;
    }

    var returnData = {};
    await DepartmentsHelpers.getDepartmentInfo(corporationId).then((data) => {
      returnData.syncedData = data.syncedData;
      returnData.latestSyncDate = data.latestSyncDate;
    });
    await DepartmentsHelpers.getSettingCommon(corporationId, collaborationService).then((data) => {
      returnData.commonInfo = {
        ...data,
      };
    });

    return returnData;
  },

  syncDepartments: function (commonInfo) {
    const func = Global.func;

    const syncDepartments = func.httpsCallable("department-synchronous");
    let param = {
      collaborationService: commonInfo.collaborationService,
      corporationId: commonInfo.corporationId,
      params: {
        mode: config.mode,
      },
    };

    if (commonInfo.collaborationService === "teams") {
      // teams
      param.params.access_token = commonInfo.access_token;
    } else {
      // wowtalk
      param.params.api_key = commonInfo.open_api_key;
      param.params.company_id = commonInfo.company_id;
      param.params.api_url = commonInfo.open_api_url;
    }

    let promise = new Promise(function (resolve, reject) {
      syncDepartments(param)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  },

  loadSyncedDepartments: async (commonInfo) => {
    var returnData = {};
    await DepartmentsHelpers.syncDepartments(commonInfo)
      .then((data) => {
        returnData = data;
      })
      .catch((e) => {
        returnData = e;
      });

    return returnData;
  },

  /**
   * フラット配列をツリーデータ構造に変換
   * @param {*} data フラット配列
   */
  getTreeData: function (data) {
    // Sort by group_name
    data.sort((a, b) => {
      if (a.group_name > b.group_name) {
        return 1;
      } else if (a.group_name < b.group_name) {
        return -1;
      } else {
        return 0;
      }
    });

    var map = {},
      node,
      rootsData = [];
    for (var i = 0; i < data.length; i++) {
      map[data[i].group_id] = i;

      for (var j = 0; j < data.length; j++) {
        if (data[i].group_id === data[j].parent_id) {
          data[i].children = [];
          break;
        }
      }
    }

    for (var k = 0; k < data.length; k++) {
      node = data[k];
      if (node.hierarchy !== "" && data[map[node.parent_id]]) {
        data[map[node.parent_id]].children.push(node);
      } else {
        rootsData.push(node);
      }
    }

    return rootsData;
  },

  /**
   * Tree用にデータ形式を変換
   * @param {*} data ツリーデータ
   */
  getTreeFormat: function (data) {
    var treeData = data.map(function (item) {
      var node = item.children
        ? {
            key: item.group_id,
            title: item.group_name,
            hierarchy: item.hierarchy,
            visible: item.visible,
            children: DepartmentsHelpers.getTreeFormat(item.children),
          }
        : {
            key: item.group_id,
            title: item.group_name,
            hierarchy: item.hierarchy,
            visible: item.visible,
          };
      return node;
    });

    return treeData;
  },

  /**
   * TreeSelect用にデータ形式を変換
   * @param {*} data ツリーデータ
   */
  getTreeSelectFormat: function (data, rootNodeDisable) {
    var treeData = data.map(function (item) {
      var node = item.children
        ? {
            key: item.group_id,
            value: item.group_id,
            title: item.group_name,
            selectable: !rootNodeDisable,
            children: DepartmentsHelpers.getTreeSelectFormat(item.children),
          }
        : {
            key: item.group_id,
            value: item.group_id,
            title: item.group_name,
            visible: item.visible,
          };
      return node;
    });

    return treeData;
  },

  getExpandedKeys: function (data) {
    var expandedKeys = [];
    data &&
      data.forEach((item) => {
        if (item.children) {
          expandedKeys.push(item.key);
          expandedKeys = expandedKeys.concat(DepartmentsHelpers.getExpandedKeys(item.children));
        }
      });

    return expandedKeys;
  },

  getCheckedKeys: function (treeData, onClickedKey = "", parentKey = "", parentVisible = false) {
    var checkedKeys = [];
    var halfCheckedKeys = [];
    var cntVisible = 0;

    treeData &&
      treeData.forEach(function (item) {
        if (parentKey !== "" && item.key !== onClickedKey && parentVisible === false) {
          item.visible = false;
        }

        if (item.children !== undefined) {
          var subCheckedKeys = DepartmentsHelpers.getCheckedKeys(item.children, onClickedKey, item.key, item.visible);
          checkedKeys = checkedKeys.concat(subCheckedKeys.checked);
          halfCheckedKeys = halfCheckedKeys.concat(subCheckedKeys.halfChecked);
        } else if (item.visible !== undefined && item.visible === true) {
          checkedKeys.push(item.key);
        }

        if (parentKey !== "") {
          if (checkedKeys.indexOf(item.key) >= 0 || halfCheckedKeys.indexOf(item.key) >= 0) {
            cntVisible++;
          }
        }
      });

    if (parentKey !== "" && cntVisible > 0) {
      if (cntVisible < treeData.length) {
        halfCheckedKeys.push(parentKey);
      } else {
        checkedKeys.push(parentKey);
      }
    } else if (parentVisible === true) {
      checkedKeys.push(parentKey);
    }

    return { checked: checkedKeys, halfChecked: halfCheckedKeys };
  },

  setCheckedKeys: function (flatData, checkedKeys, onClickedKey) {
    var flatDataEdited = flatData.map((item) => {
      if (checkedKeys.checked.indexOf(item.group_id) >= 0 || checkedKeys.halfChecked.indexOf(item.group_id) >= 0) {
        item.visible = true;
      } else {
        item.visible = false;
      }
      return item;
    });
    var treeData = DepartmentsHelpers.getTreeData(flatDataEdited);
    var treeFormatData = DepartmentsHelpers.getTreeFormat(treeData);
    var checkedKeysEdited = DepartmentsHelpers.getCheckedKeys(treeFormatData, onClickedKey);

    return { checked: checkedKeysEdited.checked, halfChecked: checkedKeysEdited.halfChecked };
  },

  updateDepartments: async function (data, corporationId) {
    const func = Global.func;
    var db = Global.firestore;

    const updateDepartments = func.httpsCallable("department-update");
    const commonRef = await db.doc(`${corporationId}/db/settings/common`).get();
    const type = commonRef.data().collaboration_service;

    const updateData = data.map((item) => {
      let body = {};
      switch (type) {
        case "wowtalk":
          body = {
            ...body,
            group_id: item.group_id,
            group_name: item.group_name,
            hierarchy: item.hierarchy,
            parent_id: item.parent_id,
            visible: item.visible,
          };
          return body;

        case "teams":
          if ("parent_id" in item) {
            body = {
              ...body,
              group_id: item.group_id,
              group_name: item.group_name,
              parent_id: item.parent_id,
              subtype: item.subtype,
              type: item.type,
              visible: item.visible,
              context: item.context,
            };
            return body;
          } else {
            body = {
              ...body,
              group_id: item.group_id,
              group_name: item.group_name,
              subtype: item.subtype,
              type: item.type,
              visible: item.visible,
              context: item.context,
            };
            return body;
          }

        default:
      }

      return body;
    });

    let promise = new Promise(function (resolve, reject) {
      updateDepartments({
        corporationId: corporationId,
        updateData: updateData,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  },

  getDepartmentInfo: function (corporationId) {
    var db = Global.firestore;
    let departmentsRef = db.doc(`${corporationId}/db/departments/department_information`);
    // let settingRef = db.collection("departments").doc("departmentInfo");

    return departmentsRef
      .get()
      .then((doc) => {
        if (doc.exists && doc.data().synced_data && Array.isArray(doc.data().synced_data)) {
          const { synced_data, latest_sync_date } = doc.data();
          return { syncedData: synced_data, latestSyncDate: latest_sync_date };
        } else {
          return { syncedData: [], latestSyncDate: "" };
        }
      })
      .catch((err) => {
        console.error("Error getting document", err);
      });
  },

  getSettingCommon: function (corporationId, collaborationService) {
    var db = Global.firestore;
    let settingRef = db.doc(`${corporationId}/db/settings/common`);

    return settingRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { teams, wowtalk } = doc.data().login_information;
          if (collaborationService === "teams") {
            return teams;
          } else if (collaborationService === "wowtalk") {
            return wowtalk;
          }
        }
      })
      .catch((err) => {
        console.error("Error getting document", err);
      });
  },
};

export default DepartmentsHelpers;
