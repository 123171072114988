import React from "react";
import { useSelector } from "react-redux";
import Global from "../../models/Global";
import moment from "moment";
import Papa from "papaparse";
import Encoding from "encoding-japanese";

// import selector
import { getHistoryList } from "../../reducks/history/selectors";

// antd
import { DatePicker, Form, message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const localize = Global.localize;

const ExportModal = (props) => {
  const selector = useSelector((state) => state);
  const historyList = getHistoryList(selector);
  const [form] = Form.useForm();

  const onOk = (event) => {
    event.preventDefault();

    form
      .validateFields(["range-picker"])
      .then((values) => {
        const start = values["range-picker"][0].format("YYYY/MM/DD");
        const end = values["range-picker"][1].format("YYYY/MM/DD");

        const fileName = "historylist.csv";
        let itemExport = [];
        const filterList = historyList.filter(
          (item) => {
            let accessDate;

            if (item.accessDate && item.accessDate.seconds) {
              accessDate = moment(item.accessDate.toDate());
            }
            else if (item.accessDate) {
              accessDate = moment(new Date(item.accessDate));
            }

            //TODO 訪問時間、と予定訪問時間は混ざっている
            let date = new Date(accessDate.format("YYYY/MM/DD"));
            return date >= new Date(start) && date <= new Date(end);
          }
        );

        for (let currentItem = 0; currentItem < filterList.length; currentItem++) {
          const comapny = filterList[currentItem].company !== "-" ? filterList[currentItem].company : "";
          const number = filterList[currentItem].number !== "-" ? filterList[currentItem].number : "";

          let answers = filterList[currentItem].customQuestionAnswer;
          let answersStr = "";
          for (var i = 0; i < answers.length; i++) {
            let answerData = answers[i];
            let label = answerData.label;
            let answer = answerData.answer;
            answersStr += `質問${i + 1}:${label},回答${i + 1}:${answer},`
          }
          answersStr = answersStr.slice(0, -1);
          itemExport.push({
            会社名: comapny,
            名前: filterList[currentItem].name,
            訪問時間: filterList[currentItem].date,
            作成者: filterList[currentItem].creatorName,
            デバイス名: filterList[currentItem].deviceName,
            対応者: filterList[currentItem].handlerName,
            受付番号: number,
            メモ: filterList[currentItem].memo,
            カスタム質問回答: answersStr
          });
        }

        const delimiterString = Papa.unparse(itemExport, {
          columns: ["会社名", "名前", "訪問時間", "作成者", "デバイス名", "対応者", "受付番号", "メモ", "カスタム質問回答"],
          quotes: true,
          quoteChar: '"',
          delimiter: ",",
          header: true,
        });

        const strArray = Encoding.stringToCode(delimiterString);
        const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
        const UintArray = new Uint8Array(convertedArray);
        const blobUrl = new Blob([UintArray], { type: "text/csv" });

        const blob = blobUrl;
        const aTag = document.createElement("a");

        aTag.download = fileName;

        if (window.navigator.msSaveBlob) {
          // for IE
          window.navigator.msSaveBlob(blob, aTag.download);
        } else if (window.URL && window.URL.createObjectURL) {
          // for Firefox
          aTag.href = window.URL.createObjectURL(blob);

          document.body.appendChild(aTag);

          aTag.click();

          document.body.removeChild(aTag);
        } else if (window.webkitURL && window.webkitURL.createObject) {
          // for Chrome
          aTag.href = (window.URL || window.webkitURL).createObjectURL(blob);

          aTag.click();
        } else {
          // for Safari
          window.open(`data:type/csv;base64,${window.Base64.encode(this.state.content)}`, "_blank");
        }

        props.handleExportModalVisible();
        message.info(localize.ExportSuccess);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <>
      <Modal
        width={"350px"}
        title={localize.MenuKeyExport}
        visible={props.visible}
        closeIcon={
          <div onClick={props.handleExportModalVisible}>
            <CloseOutlined />
          </div>
        }
        onOk={onOk}
        okText={localize.Run}
        onCancel={props.handleExportModalVisible}
        cancelText={localize.Cancel}

      >
        <Form form={form} style={{ textAlign: "center" }}>
          <Form.Item
            name="range-picker"
            rules={[
              {
                validator: (_, value) =>
                  value && value[1].diff(value[0], "days") + 1 <= 90 ? Promise.resolve() : Promise.reject(localize.HistoryListExport),
              },
            ]}
          >
            <RangePicker
              ranges={{
                "今月": [moment().startOf("month"), moment().endOf("month")],
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ExportModal;
