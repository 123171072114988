import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: "ラベル(30字)",
    dataIndex: "label",
    key: "label",
    width: "20%",
    render: (text, record) => {
      return {
        props: {
          style: { color: record["label_error"] == 1 ? "red" : "" }
        },
        children: <div>{text}</div>
      }
    }
  },
  {
    title: "説明(90字)",
    dataIndex: "description",
    key: "description",
    width: "20%",
    render: (text, record) => {
      return {
        props: {
          style: { color: record["desc_error"] == 1 ? "red" : "" }
        },
        children: <div>{text}</div>
      }
    }
  },
  {
    title: "必須",
    dataIndex: "required",
    key: "required"
  },
  {
    title: "ヒント(20字)",
    dataIndex: "hint",
    key: "hint",
    width: "15%",
    render: (text, record) => {
      return {
        props: {
          style: { color: record["hint_error"] == 1 ? "red" : "" }
        },
        children: <div>{text}</div>
      }
    }
  },
  {
    title: "キーボードタイプ",
    dataIndex: "keyboard_type",
    key: "keyboard_type"
  },
  {
    title: "受付番号あり",
    dataIndex: "reception",
    key: "reception"
  },
  {
    title: "受付番号なし",
    dataIndex: "no_reception",
    key: "no_reception"
  },
  {
    title: "カスタムボタン1",
    dataIndex: "custom_button_1",
    key: "custom_button_1"
  },
  {
    title: "カスタムボタン2",
    dataIndex: "custom_button_2",
    key: "custom_button_2"
  },
  {
    title: "カスタムボタン3",
    dataIndex: "custom_button_3",
    key: "custom_button_3"
  },
  {
    title: "カスタムボタン4",
    dataIndex: "custom_button_4",
    key: "custom_button_4"
  },
]

export default function CustomButtonSettingPreviewTable
  (props) {
  return (
    <>
      {props.hasError &&
        <div style={{ color: "red" }}>入力内容にエラーがあります</div>
      }
      <Table
        dataSource={props.dataSource}
        columns={columns}
        scroll={{ x: 1600, y: 300 }}
      />
    </>

  )
}
