import React from "react";
import { InboxOutlined, QuestionCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Button, Upload, Card, Spin, Result, Modal, Progress, Typography } from "antd";
import { red } from "@ant-design/colors";
import Papa from "papaparse";
import Global from "../models/Global";
import "../assets/common.css";
import { DepartmentsHelpers, UserHelpers } from "../helpers";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import { getLoadingState } from "reducks/loading/selectors";
import { getCommonSelector, getCorporationIdSelector, getAdvanceSelector } from "reducks/settings/selectors";
import * as settingOperations from "reducks/settings/operations";
import * as loadingOperations from "reducks/loading/operations";
import { bindActionCreators } from "redux";

const Dragger = Upload.Dragger;
const localize = Global.localize;
const { Text, Link } = Typography;

class Import extends React.Component {
  state = {
    fileList: [],
    listUsers: [],
    listDepartment: [],
    dataCsv: [],
    numberOfProcess: 0,
    numberOfSuccess: 0,
    numberOfError: 0,
    contentImportDraggerText: this.renderInitial(),
    disableBtnUpload: false,
    percent: 0,
    userloading: false,
    importing: false,
  };

  constructor(props) {
    super(props);

    this.renderImport = this.renderImport.bind(this);
    this.onAddCsvFile = this.onAddCsvFile.bind(this);
    this.onImport = this.onImport.bind(this);
    this.importUser = this.importUser.bind(this);
    this.renderLoadingImport = this.renderLoadingImport.bind(this);
  }

  componentDidMount() {
    this.setState({ userloading: true });

    this.props.actions.fetchSettings().then(() => {
      const { corporationId } = this.props;

      UserHelpers.loadUserList(corporationId).then((resData) => {
        this.setState({ listUsers: resData, userloading: false });
      });

      DepartmentsHelpers.loadData(corporationId).then((data) => {
        this.setState({ listDepartment: data });
      });
    });
  }

  async importUser(user, corporationId, common) {
    const func = Global.func;
    const { tenantId, collaborationService } = common;
    var isAdmin = user["isAdmin"] === "1" ? true : false;
    const findUser =
      collaborationService === "teams"
        ? this.state.listUsers.find((dbUser) => dbUser.email === user["email"])
        : collaborationService === "wowtalk" && this.state.listUsers.find((dbUser) => dbUser.wowTalkAccount === user["linkId"]);

    var ret;

    try {
      if (findUser) {
        // 更新
        const updatedUserBody = {
          uid: findUser.uid,
          email: user["email"],
          displayName: user["displayName"],
          isAdmin: isAdmin,
          pronounce: null,
          disabled: findUser.disabled,
          wowTalkAccount: null,
          wowTalkGroup: null,
          teamsGroup: null,
          collaborationService: collaborationService,
          corporationId: corporationId,
        };

        if (collaborationService === "teams") {
          updatedUserBody.teamsGroup = user["department"];
        } else if (collaborationService === "wowtalk") {
          updatedUserBody.wowTalkAccount = user["linkId"];
          updatedUserBody.wowTalkGroup = user["department"];
        }

        ret = await UserHelpers.callUpdateUser(updatedUserBody);
      } else {
        // 新規作成
        // LinkIDがない時も新規作成
        const createUser = func.httpsCallable("user-create");

        const createUserBody = {
          user: {
            email: user["email"],
            displayName: user["displayName"],
            pronounce: null,
            password: null,
            isAdmin: isAdmin,
            wowTalkAccount: null,
            wowTalkGroup: null,
            teamsGroup: null,
            tenantId: tenantId,
            corporationId: corporationId,
          },
        };

        if (collaborationService === "teams") {
          createUserBody.user.teamsGroup = user["department"];
        } else if (collaborationService === "wowtalk") {
          createUserBody.user.wowTalkAccount = user["linkId"];
          createUserBody.user.wowTalkGroup = user["department"];
        }

        ret = await createUser(createUserBody);
      }

      return ret;
    } catch (e) {
      console.error("importUser", e);
    }
  }

  async onImport() {
    const { common, corporationId, advance } = this.props;
    const { collaborationService } = common;
    const { domains } = advance;

    var errMsg = "";
    if (this.state.dataCsv.length !== 0) {
      this.setState({ contentImportDraggerText: "" });
      this.setState({ numberOfProcess: this.state.dataCsv.length });
      this.setState({ numberOfSuccess: 0 });
      this.setState({ numberOfError: 0 });

      var arrItem = [];
      var lineNumber = 0;
      const domainRegex = UserHelpers.createRegexFromDomainOfEmail(domains);

      if (!UserHelpers.duplicateCheck(this.state.dataCsv, "email").result) {
        // インポートファイル内でのemailの重複チェック
        const duplicateItem = UserHelpers.duplicateCheck(this.state.dataCsv, "email").duplicateItem.join(",");
        let msg = `メールアドレス「${duplicateItem}」が重複しています`;
        errMsg += lineNumber.toString() + ": " + msg + "\n";
        this.state.numberOfError = this.state.numberOfError + 1;
      } else if (collaborationService === "wowtalk" && !UserHelpers.duplicateCheck(this.state.dataCsv, "linkId").result) {
        // インポートファイル内でのLinkIdの重複チェック(WTのみ)
        const duplicateItem = UserHelpers.duplicateCheck(this.state.dataCsv, "linkId").duplicateItem.join(",");
        let msg = `LinkID「${duplicateItem}」が重複しています`;
        errMsg += lineNumber.toString() + ": " + msg + "\n";
        this.state.numberOfError = this.state.numberOfError + 1;
      } else {
        // Success
        for (let item of this.state.dataCsv) {
          const email = item["email"];
          let isAdmin = item["isAdmin"];
          const linkId = collaborationService === "wowtalk" ? item["linkId"] : null;
          const department = item["department"];
          const displayName = item["displayName"];

          if (isAdmin && isAdmin.trim().length === 0) {
            isAdmin = 0;
          }

          let groupId = null;
          let departmentName = null;
          let hierarchy = null;
          let msg = "";

          const foundLinkId = this.state.listUsers.some((el) => el.linkId === linkId);
          const foundEmail = this.state.listUsers.some((item) => item.email === email);

          const findUser =
            collaborationService === "teams"
              ? this.state.listUsers.find((dbUser) => dbUser.email === email)
              : collaborationService === "wowtalk" && this.state.listUsers.find((dbUser) => dbUser.wowTalkAccount === linkId);

          let flgCheck = false;

          this.state.listUsers.forEach((data) => {
            if (data && data.email === email && data.linkId === linkId) {
              flgCheck = true;
            }
          });

          if (collaborationService === "wowtalk") {
            this.state.listDepartment.forEach((data) => {
              departmentName = department.substring(department.lastIndexOf("/") + 1, department.length);
              hierarchy = department.substring(0, department.lastIndexOf("/"));

              if (data.hierarchy === hierarchy && data.group_name === departmentName) {
                groupId = data.group_id;
              }
            });
          } else if (collaborationService === "teams") {
            const teamName = department.substring(0, department.lastIndexOf("/"));
            const channelName = department.substring(department.lastIndexOf("/") + 1, department.length);

            const findTeamGroup = this.state.listDepartment.find((item) => item.subtype === "team" && item.group_name === teamName);
            const findChannelData = this.state.listDepartment.find((item) => item.subtype === "channel" && item.group_name === channelName);

            if (findTeamGroup && findChannelData.parent_id === findTeamGroup.group_id) {
              groupId = findChannelData.group_id;
            }
          }

          lineNumber++;

          // CSVファイルとDBの比較処理
          if (
            (collaborationService === "wowtalk" && Object.keys(item).length !== 5) ||
            (collaborationService === "teams" && Object.keys(item).length !== 4)
          ) {
            // 項目数の確認(wowtalkは5つ, msは4つ)
            msg = "項目数が正しくありません";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (collaborationService === "wowtalk" && linkId.length > 0 && foundLinkId && !flgCheck) {
            // stateとLinkId重複(WTのみ)
            msg = `LinkID「${linkId}」が重複しています`;
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (collaborationService === "wowtalk" && linkId.length > 0 && !/^[@_\\.-\w]+$/.test(linkId)) {
            // WTIDのバリデーションチェック
            msg = `正しいLinkIDを入力してください`;
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (email.trim() === "") {
            // メールアドレスの空白確認
            msg = "メールアドレスが空欄です";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (!UserHelpers.checkValidEmail(email)) {
            // メールアドレスのバリデーション確認
            msg = "有効なメールアドレスではありません";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (!domainRegex.test(email)) {
            // DBの存在するドメインチェック
            msg = localize.InputSupportDomain;
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (displayName.trim() === "") {
            // 名前の空白確認
            msg = "名前が空欄です";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (department.trim() !== "" && groupId === null) {
            // 部門の存在確認
            msg = "通知グループが存在していません";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if (isAdmin !== "0" && isAdmin !== "1") {
            // isAdminの0 or 1の確認
            msg = "isAdminは「0」または「1」しか設定できません";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else if ((collaborationService === "wowtalk" && foundEmail) || (collaborationService === "teams" && !findUser && foundEmail)) {
            msg = "メールアドレスが存在しています";
            errMsg += lineNumber.toString() + ": " + msg + "\n";
            this.state.numberOfError = this.state.numberOfError + 1;
          } else {
            arrItem.push({
              email: item.email,
              displayName: item.displayName,
              linkId: item.linkId,
              department: groupId,
              isAdmin: item.isAdmin,
            });
          }
        }
      }

      if (this.state.numberOfError === 0) {
        // Success(エラー件数はゼロ)
        var percent = 0;

        var self = this;
        var loop = setInterval(() => {
          self.setState({ percent });
        }, 1000);

        this.setState({ importing: true });

        for (var i = 0; i < arrItem.length; i++) {
          var item = arrItem[i];
          var ret;
          try {
            ret = await this.importUser(item, corporationId, common);
            console.log(`importUser ret`, ret);
          } catch (ex) {
            console.error("importUser", ex);
          }

          if (ret && ret.data.code === 200) {
            this.state.numberOfSuccess++;
          } else {
            this.state.numberOfError++;
          }

          percent = Math.round(((i + 1) / arrItem.length) * 100);
        }

        this.setState({ percent, importing: false });

        clearInterval(loop);

        if (this.state.numberOfSuccess === this.state.numberOfProcess) {
          // success
          this.setState({
            contentImportDraggerText: this.renderSuccess(),
            fileList: [],
            dataCsv: [],
            disableBtnUpload: false,
          });
        } else if (this.state.numberOfSuccess > 0 && this.state.numberOfSuccess < this.state.numberOfProcess) {
          // warning
          this.setState({
            contentImportDraggerText: this.renderError(),
            fileList: [],
            dataCsv: [],
            disableBtnUpload: false,
          });
        } else {
          // error
          console.log(`${this.state.numberOfSuccess}/${this.state.numberOfProcess}`);

          this.setState({
            contentImportDraggerText: this.renderError(),
            fileList: [],
            dataCsv: [],
            disableBtnUpload: false,
          });
        }
      } else {
        // Error(エラー件数が１以上)
        const modal = Modal.warning();
        modal.update({
          title: "エラー",
          content: <TextArea disabled={true} rows={6} value={errMsg} style={{ color: red.primary }} />,
          // transitionName: "am-slide-down",
          cancelText: localize.Cancel,
          width: 450,
          onOk: async (e) => { },
          onCancel: (e) => {
            process.nextTick(() => {
              modal.destroy();
            });
          },
        });

        this.setState({
          contentImportDraggerText: this.renderError(),
          fileList: [],
          dataCsv: [],
          disableBtnUpload: false,
        });
      }
    } else {
      // null
      this.setState({
        contentImportDraggerText: this.renderError(),
      });
    }
    return;
  }

  onAddCsvFile(result) {
    const data = result.data;
    this.setState({ dataCsv: data, fileList: [], disableBtnUpload: true });
    this.onImport();
  }

  renderImport() {
    var self = this;
    const { common } = this.props;
    const { collaborationService } = common;
    return (
      <Card title={localize.ImportTitle} style={{ margin: "16px" }}>
        <div className="wrap-card">
          <div className="attention">
            <div className="icon-warning">
              <WarningOutlined />
            </div>
            <div className="attention-content">
              <div>{localize.Attention1}</div>
              <div>{localize.Attention2}</div>
              <div>{localize.Attention3}</div>
              <div>{localize.Attention4}</div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <Spin size="large" spinning={this.state.userloading}>
            <Dragger
              accept=".csv"
              // onChange={this.handleDraggerChange}
              fileList={this.state.fileList}
              multiple={false}
              beforeUpload={(file) => {
                Papa.parse(file, {
                  beforeFirstChunk: function (chunk) {
                    var rows = chunk.split(/\r\n|\r|\n/);
                    var headings =
                      collaborationService === "wowtalk"
                        ? "email,displayName,linkId,department,isAdmin"
                        : collaborationService === "teams" && "email,displayName,department,isAdmin";
                    rows[0] = headings;
                    return rows.join("\r\n");
                  },
                  encoding: "Shift-JIS",
                  header: true,
                  skipEmptyLines: true,
                  complete: this.onAddCsvFile,
                });
                return false;
              }}
            >
              {this.state.importing ? this.renderLoadingImport() : self.state.contentImportDraggerText}
            </Dragger>

            <p className="upload-title">{localize.ImportDraggerTextTitle}</p>

            <Upload
              className="upload"
              disabled={this.state.disableBtnUpload}
              accept=".csv"
              multiple={false}
              fileList={this.state.fileList}
              beforeUpload={(file) => {
                Papa.parse(file, {
                  beforeFirstChunk: function (chunk) {
                    var rows = chunk.split(/\r\n|\r|\n/);
                    var headings =
                      collaborationService === "teams"
                        ? "email,displayName,department,isAdmin"
                        : collaborationService === "wowtalk" && "email,displayName,linkId,department,isAdmin";
                    rows[0] = headings;
                    return rows.join("\r\n");
                  },
                  encoding: "Shift-JIS",
                  header: true,
                  skipEmptyLines: true,
                  complete: this.onAddCsvFile,
                });
                return false;
              }}
            >
              <Button type="primary" className="button-upload" disabled={this.state.disableBtnUpload}>
                {localize.ImportButtonTitle}
              </Button>
              <span className="note-button">{localize.Note}</span>
              <div style={{ clear: "both" }} />
            </Upload>
          </Spin>

          <div className="help">
            <QuestionCircleFilled className="icon-question" />
            <span className="text-help">{localize.Help}</span>
            <div style={{ clear: "both" }} />
          </div>
          <div className="link">
            <span style={{ marginLeft: "8px", marginRight: "16px" }}></span>
            {collaborationService === "teams" ? (
              <Link href="https://doc.wowdesk.jp/wowdesk_userimport_teams.csv" target="_blank">
                {localize.LinkTitle}
              </Link>
            ) : (
              collaborationService === "wowtalk" && (
                <Link href="https://doc.wowdesk.jp/wowdesk_userimport_wowtalk.csv" target="_blank">
                  {localize.LinkTitle}
                </Link>
              )
            )}
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </Card>
    );
  }

  renderInitial() {
    return (
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{localize.DraggerText}</p>
      </div>
    );
  }

  renderError() {
    return (
      <Result
        style={{ border: "none" }}
        status="warning"
        title={localize.ImportErrorPopupContentCannotImport1}
        subTitle={localize.ImportErrorPopupContentCannotImport2}
      />
    );
  }

  renderSuccess() {
    return <Result style={{ border: "none" }} status="info" title={localize.ImportSuccess} />;
  }

  renderLoadingImport() {
    return (
      <div>
        <Progress type="circle" percent={this.state.percent} width={80} />
        <p />
        <p>
          <Text>{localize.ContentLoadingImport}</Text>
        </p>
      </div>
    );
  }

  renderLoading() {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
        <Spin size="large" tip={localize.SpinProcessing} />
      </div>
    );
  }

  render() {
    // console.log(`this.state`, this.state);
    return this.state.loading ? this.renderLoading() : this.renderImport();
  }
}

const mapStateToProps = (state) => {
  return {
    loading: getLoadingState(state),
    advance: getAdvanceSelector(state),
    common: getCommonSelector(state),
    corporationId: getCorporationIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations, ...loadingOperations }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Import);
