import Global from "models/Global";
import { IndexedDBHelpers } from "helpers";

// import actions
import { fetchSettingAction } from "./actions";

const db = Global.firestore;

export const fetchSettings = (routingPropsCorporationId) => {
  return async (dispatch, getState) => {
    const prevCorporationId = getState().settings.corporationId;

    if (!prevCorporationId) {
      try {
        let corporationId = null;

        // console.log(`routingPropsCorporationId`, routingPropsCorporationId);
        if (routingPropsCorporationId) {
          corporationId = String(routingPropsCorporationId.corporationId);
        } else {
          const indexedDBTable = await IndexedDBHelpers.getTable({ dbName: "wowdeskLocalStorageDb", tableName: "user", key: "login" });
          // console.log(`indexedDBTable`, indexedDBTable);
          corporationId = String(indexedDBTable.corporationId);
        }

        // console.log(`routingPropsCorporationId`, routingPropsCorporationId);
        // console.log(`indexedDBTable.corporationId`, indexedDBTable.corporationId);
        // const corporationId = routingPropsCorporationId
        //   ? routingPropsCorporationId.corporationId
        //   : indexedDBTable.corporationId && indexedDBTable.corporationId;

        const advanceDoc = await db.doc(`${corporationId}/db/settings/advance`).get();
        const commonDoc = await db.doc(`${corporationId}/db/settings/common`).get();

        await dispatch(
          fetchSettingAction({
            advance: advanceDoc.data(),
            common: {
              collaborationService: commonDoc.data().collaboration_service,
              tenantId: commonDoc.data().tenant_id,
              teamsBotTenantId: commonDoc.data().teams_bot_tenant_id,
            },
            corporationId: corporationId,
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };
};

export const resetSetting = () => {
  return async (dispatch) => {
    dispatch(
      fetchSettingAction({
        advance: null,
        common: {
          collaborationService: null,
          tenantId: null,
        },
        corporationId: null,
      })
    );
  };
};
